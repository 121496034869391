.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3.2);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}
.carousel-content.show-5 > * {
  width: calc(100% / 5);
}
.carousel-content.show-6 > * {
  width: calc(100% / 6);
}

.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  background: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-family: 'SFProHeavy';
  color: #2e2e3b;
}

.left-arrow {
  left: 0.1rem;
}

.right-arrow {
  right: 0.1rem;
}

@media (hover: none) and (pointer: coarse) {
  .left-arrow,
  .right-arrow {
    display: none;
  }
}
