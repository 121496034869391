.sub-tab-container {
  background: #ffff;
  height: 2rem;
  width: 100%;
  position: relative;
  /* padding: 0 1.5rem; */
}

.sub-tab-item-container {
  margin-right: 0.1rem;
  border-bottom: 0.06rem solid #ebebee;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
