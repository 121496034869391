#homeSubmenu {
  margin-top: 0rem;
  background: #f5f5f5;
}
.subnavstyle {
  display: flex;
  align-items: center;
  padding-bottom: 0rem;
  height: 2rem;
  padding-left: 2rem;
}
.subnavstyle:hover {
  background: #e4e2e2;
}
.nav-border-radius {
  border-radius: 0.28rem !important;
}

.delete-icon-sidebar {
  width: 20px;
  color: grey;
  cursor: pointer;
  margin-left: 10px;
  padding-bottom: 5px;
}

.edit-icon-report {
  width: 15px;
  color: white;
  margin-left: 5px;
  padding-bottom: 4px;
}

.edit-button-report {
  border: 1px solid #7030a0;
  color: black;
  position: absolute;
  right: 40px;
  font-family: 'SFProRoundedRegular';
}
.chart-title-style {
  font-size: 1.01rem;
  letter-spacing: 0.1rem;
}
.report-title-style {
  font-family: 'SFProRoundedRegular';
  letter-spacing: 0.01rem;
  font-size: 1.2rem;
}
