.landing-background {
  width: 100%;
  height: 100vh;
  /* background-image: url('../../images/landing-background.jpg'); */
  background-image: url('../../images/background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

.nav-bar-landing-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5656568c;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.landing-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 1050px;
  margin: auto;
}

.landing-page-profile-card {
  height: 15rem;
  /* background-color: #fafafac9; */
  background: linear-gradient(30deg, rgba(183, 203, 255, 0.85) 0%, rgba(218, 235, 255, 1) 100%);
  border-radius: 10px;
  margin-right: 1rem;
  box-shadow: 1px 1px 7px 0px #0000008f;
  padding: 1rem;
}

.landing-page-apps-card {
  height: 15rem;
  background-color: #fafafa6b;
  border-radius: 10px;
  margin-right: 1rem;
  box-shadow: 1px 1px 7px 0px #0000008f;
  padding: 1.5rem;
}

#landing-profile-photo {
  width: 6rem;
  height: 6rem;
  clip-path: circle();
}

.profile-card-title {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.landing-page-apps-link {
  padding-top: 5px;
  text-decoration: none;
  color: black;
}

.landing-page-apps-link:hover {
  padding-top: 5px;
  text-decoration: none;
  color: black;
}

.landing-app-list-item {
  text-align: center;
  cursor: pointer;
}

.landing-app-list-item:hover {
  text-align: center;
  cursor: pointer;
  background-color: #ffffff6e;
  border-radius: 10px;
}

.landing-page-title {
  font-size: 1.5rem;
  color: white;
}

.landing-menu-icon {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 18px;
}
.wrapper {
  width: 100%;
}
