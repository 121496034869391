.contentGrid {
  width: 100%;
}

.accordion-styles {
  box-shadow: none !important;
  position: relative;
}

#filter-container-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-title-accordion {
  min-width: 7rem;
}

.accordion-container-styles {
  padding-left: 7rem;
  padding-top: 1rem;
  /* max-height: 220px;
  overflow-y: auto; */
}

.buttons-filter-container {
  margin-top: 0.9rem;
  padding-right: 1rem;
}

.searchbar-styles {
  margin-left: 7.5rem;
  position: absolute;
  top: 0.80rem;
  max-width: 50rem;
}

.filter-content-layout {
  /* max-height: 70vh; */
  /* overflow-y: scroll; */
  padding-left: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-right: 16px;
}

.filter-container {
  margin-left: 17px;
  background-color: #f9f9f9;
  margin-right: -15px;
}

#select-field {
  width: 350px;
  display: flex;
  align-items: center;
}

.title-timeline {
  min-width: 150px;
}

.timeline-container-styles {
  padding:1rem;
  background-color: white;
}

.global-filter-count {
  padding: 0px 5px;
  background-color: white;
  font-size: .75rem;
  color: #7030a0;
  font-weight: 700;
  border-radius: 10px;
  margin-left: 5px;
}

.input-search-styles {
  width: 100% !important;
}
.input-search-styles:hover {
  border-bottom: 1px solid grey;
  width: 100% !important;
}