.sub-tab-item-container .sub-tab-item {
  /* margin-right: 0.25rem; */
}
.sub-tab-item-container .sub-tab-item .subtab {
  width: 19.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border: 0.06rem solid #ebebee;
  cursor: pointer;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.sub-tab-item-container .sub-tab-item .slide-width {
  width: 100%;
}
.sub-tab-item-container .sub-tab-item .subtab:hover {
  background: #ebebeb;
}
.sub-tab-item-container .sub-tab-item .subtab .subtab-label {
  width: 90%;
  text-align: center;
}
.sub-tab-item-container .sub-tab-item .active-subtab {
  background: #f5f5f5;
  /* border-top: 5px solid #7030a0; */
}
