body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'SFProHeavy';
  src: local('SFProFont'), url('./fonts/SFProFont/SFPRODISPLAYBOLD.OTF') format('opentype');
}
@font-face {
  font-family: 'SFProRounded';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'SFProRoundedRegular';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'SFProRoundedLight';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Light.otf') format('opentype');
}
@font-face {
  font-family: 'SFProTextHeavy';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Text-Heavy.otf') format('opentype');
}
@font-face {
  font-family: 'SFProTextLight';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Text-Light.otf') format('opentype');
}
@font-face {
  font-family: 'SFProDisplayRegular';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Display-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'SFProDisplaySemibold';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Display-Semibold.otf') format('opentype');
}
@font-face {
  font-family: 'SFProDisplayThin';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Display-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'SFProRoundedBlack';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Black.otf') format('opentype');
}
@font-face {
  font-family: 'SFProRoundedBold';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'SFProRoundedHeavy';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Rounded-Heavy.otf') format('opentype');
}
@font-face {
  font-family: 'SFProDisplayLight';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Display-Light.otf') format('opentype');
}
@font-face {
  font-family: 'SFProDisplayMedium';
  src: local('SFProFont'), url('./fonts/SFProFont/SF-Pro-Display-Medium.otf') format('opentype');
}
