.sf-pro-display-heavy {
  font-family: 'SFProHeavy';
}
.sf-pro-rounded-thin {
  font-family: 'SFProRounded';
}
.sf-pro-rounded-regular {
  font-family: 'SFProRoundedRegular';
}
.sf-pro-rounded-light {
  font-family: 'SFProRoundedLight';
}
.sf-pro-display-medium {
  font-family: 'SFProDisplayMedium';
}
