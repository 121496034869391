.login-background {
    width: 58%;
    height: 100vh;
    /* background-image: url('../../images/login-background.jpg'); */
    /* background-image: url('../../images/login-background.svg'); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .nav-bar-login-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #5656568c;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  
  .login-page-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 1050px;
    margin: auto;
  }
  
  .login-page-profile-card {
    height: 18rem;
    width: 25rem;
  }
  
  .login-page-apps-card {
    height: 15rem;
    background-color: #fafafa6b;
    border-radius: 10px;
    margin-right: 1rem;
    box-shadow: 1px 1px 7px 0px #0000008f;
    padding: 1.5rem;
  }
  
  #login-profile-photo {
    width: 6rem;
    height: 6rem;
    clip-path: circle();
  }
  
  .profile-card-title {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  
  .login-page-apps-link {
    padding-top: 5px;
    text-decoration: none;
    color: black;
  }
  
  .login-page-apps-link:hover {
    padding-top: 5px;
    text-decoration: none;
    color: black;
  }
  
  .login-app-list-item {
    text-align: center;
    cursor: pointer;
  }
  
  .login-app-list-item:hover {
    text-align: center;
    cursor: pointer;
    background-color: #ffffff6e;
    border-radius: 10px;
  }
  
  .login-page-title {
    font-size: 1.5rem;
    color: white;
  }
  
  .login-menu-icon {
    padding-top: 5px;
    padding-bottom:5px;
    border-radius: 18px;
  }

  .login-fields {
    margin-top: 1rem;
  }

  .button-submit-login {
    margin-top: 1rem;
  }