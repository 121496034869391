#navbarSupportedContent .title {
  color: #ffff;
}

.topnav-icon-style {
  color: white;
  margin-right: 15px;
}

.navbar-full-width {
  margin-left: -15px;
  margin-right: -15px;
}
