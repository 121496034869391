#sidebar {
  min-width: 220px;
  max-width: 275px;
  background: #f9f9f9;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  /* height: calc(100vh - 5px); */
  overflow-y: auto;
  font-family: 'SFProRoundedRegular';
  box-shadow: 4px 0 5px -2px #e0e0e0;
}

#sidebar .simpleBarHeight {
  max-height: calc(100vh - 10px);
}

#sidebar.active {
  margin-left: -150px;
}
.active-padding-left {
  padding-left: 10.5rem !important;
}
.inactive-padding {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important
  ;
}

#sidebar .logo {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
#sidebar .logo span {
  display: block;
}
#sidebar .toggle-open-style {
  display: flex;
  justify-content: flex-start;
}
#sidebar ul.components {
  padding: 0;
}
#sidebar ul li {
  font-size: 16px;
}
#sidebar ul li.active {
  /* background: #f0efef; */
}
#sidebar ul li > ul {
  margin-left: 0px;
}
#sidebar ul li > ul li {
  font-size: 14px;
  cursor: pointer;
}

#sidebar ul li a {
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#sidebar ul li a:hover {
  color: #f0efef;
}
#sidebar ul li.active > a {
  background: transparent;
  color: #f8b739;
}
#sidebar .toggle-li {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -300px;
  }
  #sidebar.active {
    margin-left: 0;
  }
}

a[data-toggle='collapse'] {
  position: relative;
}

/* .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
} */

.dropdown-toggle:hover {
  background: #f0efef;
}

.footer p {
  color: rgba(255, 255, 255, 0.5);
}

.nav-items-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nav-items-title {
  color: #a1a1a1;
  cursor: default;
}

.nav-item-ul {
  width: 100%;
}
.nav-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 2rem;
  border-radius: 0.25rem;
}
.nav-items-open {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 20px !important;
}
.nav-items-open:hover {
  background: #e1e1e1;
}
.nav-active-style {
  background: #e1e1e1;
  /* border-radius: 10px; */
}
.selected-nav-style {
  background: #ebebeb;
  font-weight: bold;
}
.nav-item-search {
  background: #eaeaea;
  border-radius: 10px;
}
.nav-item-icon {
  margin-right: 0.2rem;
}
.nav-item-drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.nav-item-drop {
  /* margin-left: 0.8rem; */
}
.nav-list-title-1 {
  color: #595959;
  width: 85%;
}
.nav-list-title-2 {
  margin-left: 0.2rem;
}
.nav-list-title-selected {
  font-weight: 800;
}
.nosubmitform {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  margin: 0 0 30px;
}
#side-nav-search-form {
  border: none;
  background: #eaeaea;
  margin: 0;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  border: none;
  padding: 0;
}

input.search-input[type='search']:focus {
  box-shadow: none;
  border-color: none;
  outline: none;
  cursor: default;
}
input.search-input {
  border: none;
  width: 80%;
  background-color: #eaeaea;
  /* padding: 9px 4px 9px 40px; */
}

.collapse > li {
  color: #595959;
}

@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}
.nav-toggle-icon {
  cursor: pointer;
}
.horizontal-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}
